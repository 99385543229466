import React from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay';
import { Message_message$data, Message_message$key } from './__generated__/Message_message.graphql';
import Notice, { MessageStatus } from '../../atoms/Notice/Notice';

const fragmentSpec = graphql`
  fragment Message_message on Message {
    id
    title
    message
    type
  }
`;
export interface Props {
  messageRef: Message_message$key;
  className?: string;
}

export function mapMessageTypeToMessageStatus(type: Message_message$data['type']): MessageStatus {
  switch (type) {
    case 'SUCCESS':
      return 'success';
    case 'ERROR':
      return 'error';
    case 'WARNING':
      return 'warning';
    case 'INFO':
    default:
      return 'info';
  }
}

const Message: React.FC<Props> = ({ messageRef, className }: Props) => {
  const message = useFragment(fragmentSpec, messageRef);

  return (
    <Notice
      component="article"
      title={message.title || undefined}
      status={mapMessageTypeToMessageStatus(message.type)}
      message={message.message}
      className={className}
    />
  );
};
export default Message;
