import React, { useEffect, useState } from 'react';
import classes from './ShareButtons.module.scss';

import { shareFacebook, initFacebook } from './socialShare';
import Facebook from './icons/Facebook.svg';
import Twitter from './icons/Twitter.svg';
import Copy from './icons/Copy.svg';
import clsx from 'clsx';
import useFrontendEventTracker from '../../../hooks/useFrontendEventTracker';
import { useSocialAllowed } from '../../templates/TrackingProvider/TrackingProvider';
import Txt from '../../atoms/Txt/Txt';

export interface TypeProps {
  type: 'offer' | 'balance' | 'surveyComplete' | 'noTemplate';
  className?: string;
}
type BalanceProps =
  | { type: 'balance'; balance?: String }
  | { type: 'offer'; balance?: never }
  | { type: 'surveyComplete'; balance?: never }
  | { type: 'noTemplate'; balance?: never };
type OfferProps =
  | { type: 'balance'; offer?: never }
  | { type: 'offer'; offer?: Offer }
  | { type: 'surveyComplete'; offer?: never }
  | { type: 'noTemplate'; offer?: never };
type SurveyCompleteProps =
  | { type: 'balance'; surveyComplete?: never }
  | { type: 'offer'; surveyComplete?: never }
  | { type: 'surveyComplete'; surveyComplete?: { amount: string } }
  | { type: 'noTemplate'; surveyComplete?: never };
type NoTemplateProps =
  | { type: 'balance'; shareInfo?: never }
  | { type: 'offer'; shareInfo?: never }
  | { type: 'surveyComplete'; shareInfo?: never }
  | { type: 'noTemplate'; shareInfo?: ShareInfo };
export type Props = OfferProps & TypeProps & BalanceProps & SurveyCompleteProps & NoTemplateProps;

type Offer = {
  title: string;
  url: string;
  paysReward: boolean;
  rewardLabel: string;
  advertiser: { name: string };
};

type ShareInfo = {
  title?: string | null;
  description: string;
  url: string;
  imageUrl: string;
  ref: string;
};

const balanceDescriptionGenerator = (accountName: string, balance: String) =>
  `I've earned ${balance} with Qmee! You can too - join ${accountName} to get cash rewards when shopping online and answering surveys`;

const offerDescriptionGenerator = (accountName: string, offer: Offer) =>
  offer.paysReward
    ? `Check out this ${accountName} cashback offer! You can earn ${offer.rewardLabel} cashback when you take part in this offer from ${offer.advertiser.name} - have a look at the full offer here.`
    : `Check out this ${accountName} deal for ${offer.advertiser.name}! ${offer.title} Have a look at the full deal here.`;

const surveyCompleteDescriptionGenerator = (surveyComplete: string) =>
  `I've just earned another ${surveyComplete} by sharing my opinion & completing a survey!`;

export default function ShareButtons({
  offer,
  type,
  balance,
  surveyComplete,
  shareInfo,
  className,
}: Props) {
  const HOME_URL = 'https://www.qmee.com';
  const socialAllowed = useSocialAllowed();

  const [copyMessage, showCopyMessage] = useState(false);
  const trackFrontendEvent = useFrontendEventTracker();
  const DEFAULTS = {
    surveyComplete: { amount: 'cash out', url: 'https://www.qmee.com/' },
    offer: {
      title: '',
      url: HOME_URL,
      paysReward: true,
      rewardLabel: '',
      advertiser: { name: 'Qmee' },
    },
    shareInfo: {
      title: 'Check Out Qmee',
      description: 'Earn real cash when you search, shop & take surveys',
      url: HOME_URL,
      imageUrl: 'oinq.png',
      ref: HOME_URL,
    },
    balance: 'cash',
  };

  function generateShareUrl(offerUrl: string) {
    return `https://www.facebook.com/dialog/share?app_id=123775991104422&display=popup&href=${encodeURIComponent(
      offer ? offerUrl : DEFAULTS.surveyComplete.url,
    )}&redirect_uri=${encodeURIComponent(offer ? offerUrl : DEFAULTS.surveyComplete.url)}`;
  }

  const generateTitle = (shareInfo: ShareInfo | undefined) => {
    return shareInfo?.title === null
      ? ''
      : `${shareInfo?.title ? shareInfo?.title : DEFAULTS.shareInfo.title} - `;
  };

  useEffect(() => {
    if (socialAllowed) {
      initFacebook();
    }
  }, [socialAllowed]);

  const handleFacebookClick = () =>
    function shareToFacebook() {
      trackFrontendEvent({
        type: 'share_button_clicked',
        value: 'Facebook',
      });
      let description: string;
      let title: string;
      let url: string;
      let imageUrl: string;
      let ref: string;
      switch (type) {
        case 'balance':
          title = `I've earned ${balance} with Qmee!`;
          description = `${balanceDescriptionGenerator('Qmee', balance || DEFAULTS.balance)}`;
          url = HOME_URL;
          imageUrl = 'oinq.png';
          ref = url;
          break;
        case 'offer':
          title = 'Check out this Qmee cashback Offer!';
          description = `${offerDescriptionGenerator('Qmee', offer || DEFAULTS.offer)}`;
          url = offer ? offer.url : DEFAULTS.offer.url;
          imageUrl = 'oinq.png';
          ref = url;
          break;
        case 'surveyComplete':
          title = 'I just completed another survey on Qmee!';
          description = surveyCompleteDescriptionGenerator(
            surveyComplete ? surveyComplete.amount : DEFAULTS.surveyComplete.amount,
          );
          url = HOME_URL;
          imageUrl = 'oinq.png';
          ref = url;
          break;
        default:
          title = shareInfo?.title || DEFAULTS.shareInfo.title;
          description = shareInfo ? shareInfo.description : DEFAULTS.shareInfo.description;
          url = shareInfo ? shareInfo.url : DEFAULTS.shareInfo.url;
          imageUrl = shareInfo ? shareInfo.imageUrl : DEFAULTS.shareInfo.imageUrl;
          ref = shareInfo ? shareInfo.ref : DEFAULTS.shareInfo.ref;
      }
      if (socialAllowed) {
        shareFacebook({ url, title, description, imageUrl, ref });
      } else {
        window.open(generateShareUrl(url));
      }
    };

  const handleTwitterClick = () =>
    function shareToTwitter() {
      trackFrontendEvent({
        type: 'share_button_clicked',
        value: 'Twitter',
      });
      let tweetText: string;
      let url: string;
      switch (type) {
        case 'balance':
          tweetText = balanceDescriptionGenerator('@Qmee', balance || DEFAULTS.balance);
          url = HOME_URL;
          break;
        case 'offer':
          tweetText = offerDescriptionGenerator('@Qmee', offer || DEFAULTS.offer);
          url = offer ? offer.url : DEFAULTS.offer.url;
          break;
        case 'surveyComplete':
          tweetText = surveyCompleteDescriptionGenerator(
            surveyComplete ? surveyComplete.amount : DEFAULTS.surveyComplete.amount,
          );
          url = HOME_URL;
          break;
        default:
          const tweetTitle = generateTitle(shareInfo);
          tweetText = `${tweetTitle}${
            shareInfo ? shareInfo.description : DEFAULTS.shareInfo.description
          }`.replaceAll('Qmee', '@Qmee');
          url = shareInfo ? shareInfo.url : DEFAULTS.shareInfo.url;
      }
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          tweetText,
        )}&url=${encodeURIComponent(url)}`,
        '_blank',
      );
    };

  const handleCopyClick = () =>
    function shareToClipboard() {
      trackFrontendEvent({
        type: 'share_button_clicked',
        value: 'Copy',
      });
      switch (type) {
        case 'balance':
          navigator.clipboard.writeText(
            `${balanceDescriptionGenerator('Qmee', balance || DEFAULTS.balance)} ${HOME_URL}`,
          );
          break;
        case 'offer':
          navigator.clipboard.writeText(`${offer ? offer.url : DEFAULTS.offer.url}`);
          break;
        case 'surveyComplete':
          navigator.clipboard.writeText(
            `${surveyCompleteDescriptionGenerator(
              surveyComplete ? surveyComplete.amount : DEFAULTS.surveyComplete.amount,
            )} ${HOME_URL}`,
          );
          break;
        default:
          const copyClickTitle = generateTitle(shareInfo);
          navigator.clipboard.writeText(
            shareInfo
              ? `${copyClickTitle}${shareInfo.description} ${shareInfo.url}`
              : `${DEFAULTS.shareInfo.title} - ${DEFAULTS.shareInfo.description} ${DEFAULTS.shareInfo.url}`,
          );
      }
      showCopyMessage(true);
      setTimeout(() => {
        showCopyMessage(false);
      }, 1500);
    };

  return (
    <div className={clsx(classes.root, className)}>
      {copyMessage && (
        <div className={classes.message}>
          <Txt component="p" className={classes.messageText}>
            Copied!
          </Txt>
        </div>
      )}
      <button type="button" onClick={handleFacebookClick()} className={classes.socialButton}>
        <Facebook alt="facebook" className={classes.socialIcon} />
      </button>
      <button type="button" onClick={handleTwitterClick()} className={classes.socialButton}>
        <Twitter alt="twitter" className={classes.socialIcon} />
      </button>
      <button type="button" onClick={handleCopyClick()} className={classes.socialButton}>
        <Copy alt="copy" className={classes.socialIcon} />
      </button>
    </div>
  );
}
