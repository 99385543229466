import React from 'react';
import { graphql } from 'react-relay';
import CompleteIcon from './images/complete.svg';
import IncompleteIcon from './images/incomplete.svg';
import PendingIcon from './images/pending.svg';
import DisabledIcon from './images/disabled.svg';
import DemoSurvey from './images/Survey.svg';
import FullStreak from './images/Streak.svg';
import useLiveFragment from '../../../relay/useLiveFragment';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import Txt from '../../atoms/Txt/Txt';
import ShareButtons from '../../molecules/ShareButtons/ShareButtons';
import clsx from 'clsx';
import { Streak_streak$data, Streak_streak$key } from './__generated__/Streak_streak.graphql';
import classes from './Streak.module.scss';
import generateDescriptionData from './generateDescriptionData';

const fragmentSpec = graphql`
  fragment Streak_streak on GamificationStreak
  @refetchable(queryName: "StreakGamificationStreakRefetchQuery") {
    expiresAt
    length
    unlocksAt
  }
`;

export interface Props {
  streakRef: Streak_streak$key;
  className?: string;
}

const Streak: React.FC<Props> = ({ streakRef, className }) => {
  const streak: Streak_streak$data = useLiveFragment(fragmentSpec, streakRef);
  const [descriptionData, setDescriptionData] = React.useState(() =>
    generateDescriptionData(streak),
  );

  React.useEffect(() => {
    setDescriptionData(generateDescriptionData(streak));
    const interval = setInterval(() => {
      setDescriptionData(generateDescriptionData(streak));
    }, 20000);
    return () => clearInterval(interval);
  }, [streak]);

  const activeIndex = streak.length < 5 ? streak.length : 4;
  const statusArray = Array(5)
    .fill('DISABLED')
    .fill('COMPLETE', 0, activeIndex)
    .fill(
      descriptionData.todaysStatus === 'COMPLETE' ? 'PENDING' : 'INCOMPLETE',
      activeIndex,
      activeIndex + 1,
    );

  let warningStyle: string | undefined;
  if (!descriptionData.time) {
    warningStyle = 'grey';
  } else if (descriptionData.time.length <= 3 && descriptionData.todaysStatus == 'INCOMPLETE') {
    warningStyle = 'red';
  } else if (descriptionData.todaysStatus == 'INCOMPLETE') {
    warningStyle = 'amber';
  } else if (streak.length >= 5) {
    warningStyle = 'green';
  }

  const timeClasses = {
    [classes.grey]: warningStyle == 'grey',
    [classes.amber]: warningStyle == 'amber',
    [classes.red]: warningStyle == 'red',
  };
  const mainColorClasses = {
    ...timeClasses,
    [classes.green]: warningStyle == 'green',
  };
  const active = { [classes.grey]: streak.length < 5 };

  return (
    <div className={clsx(classes.streakRootContainer, className)}>
      <Tooltip
        tipClassName={classes.tip}
        tip={
          <>
            <Txt component="h5">
              Activate all 5 Oinqs and receive a 10% increase in survey rewards while your streak is
              active!
            </Txt>
            <FullStreak className={classes.fullStreak} />
            <Txt component="h5">Keep your streak active!</Txt>
            <Txt component="p">
              Retain your bonus by completing at least one survey each day or by answering the daily
              poll.
              <DemoSurvey className={classes.demoSurvey} />
              <Txt component="b">See how long you can maintain your streak!</Txt>
            </Txt>
            <span className={classes.divider}></span>
            {streak.length > 0 && (
              <Txt component="p" className={'complete'}>
                Share your <Txt component="b">{streak.length}</Txt> day streak
              </Txt>
            )}
            <ShareButtons
              type="noTemplate"
              shareInfo={{
                title:
                  streak.length > 0
                    ? `I've got a ${streak.length} day streak on Qmee`
                    : `I'm using Qmee`,
                description:
                  "I'm earning with Qmee! You can too - join to get cash rewards when shopping online and answering surveys",
                url: 'https://www.qmee.com',
                imageUrl: '',
                ref: '',
              }}
            />
          </>
        }
        wrapperClassName={classes.streakLayoutBox}
      >
        <div className={classes.leftSide}>
          <div className={classes.streakIconsRow}>
            {Boolean(streak.length >= 5) && (
              <CompleteIcon className={clsx(classes.statusIcon, classes.fillerStatus)} />
            )}
            {statusArray.map((status, i) => {
              const key = `${i}-${status}`;
              switch (status) {
                case 'COMPLETE':
                  return <CompleteIcon key={key} className={classes.statusIcon} />;
                case 'INCOMPLETE':
                  return (
                    <IncompleteIcon
                      key={key}
                      className={clsx(mainColorClasses, classes.flash, classes.statusIcon)}
                      stroke={warningStyle}
                      floodColor={warningStyle}
                    />
                  );
                case 'PENDING':
                  return <PendingIcon key={key} className={classes.statusIcon} />;
                case 'DISABLED':
                  return <DisabledIcon key={key} className={classes.statusIcon} />;
                default:
                  return null;
              }
            })}
            <Txt className={classes.rightSideInfo}>
              <Txt
                component="span"
                className={clsx(mainColorClasses, active)}
                translateOptions={{ vars: [streak.length] }}
              >
                {streak.length >= 5 ? `%1s; days` : 'Unlock'}
              </Txt>
              <Txt component="span" className={clsx(mainColorClasses, active)} skipTranslation>
                +10%
              </Txt>
            </Txt>
          </div>
          <div
            className={clsx(
              { [classes.central]: !descriptionData.time },
              classes.descriptionContainer,
            )}
          >
            <Txt component="b" className={clsx(timeClasses, classes.message)}>
              {descriptionData.description.message}
            </Txt>
          </div>
          <div
            className={clsx(classes.descriptionContainer, {
              [classes.fade]: streak.length > 0,
            })}
          >
            <Txt component="b" className={clsx(timeClasses, classes.precursorMessage)}>
              {descriptionData.description.precursorMessage}
            </Txt>
            {descriptionData.time ? (
              <Txt
                component="span"
                className={clsx(timeClasses, classes.timer, classes.rightMessage)}
                skipTranslation
              >
                {descriptionData.time}
              </Txt>
            ) : (
              <Txt component="span" className={clsx(classes.whatsThis)}>
                (Whats this?)
              </Txt>
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default Streak;
