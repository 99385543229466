/**
 * @generated SignedSource<<5ffce5ed85ecd8b3e7fafd3bc1349bd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MessageType = "ERROR" | "INFO" | "SUCCESS" | "WARNING";
import { FragmentRefs } from "relay-runtime";
export type Message_message$data = {
  readonly id: string;
  readonly title: string | null;
  readonly message: string;
  readonly type: MessageType;
  readonly " $fragmentType": "Message_message";
};
export type Message_message$key = {
  readonly " $data"?: Message_message$data;
  readonly " $fragmentSpreads": FragmentRefs<"Message_message">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Message_message",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Message",
  "abstractKey": null
};

(node as any).hash = "58d158b5b038f85229336be759c83157";

export default node;
