import clsx from 'clsx';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import ChevronIcon from '../../assets/ChevronIcon';
import Txt from '../Txt/Txt';
import classes from './Collapsible.module.scss';

export interface Props {
  title?: React.ReactNode;
  startOpen?: boolean;
  className?: string;
  id?: string;
  open?: boolean;
  skipTitleTranslation?: boolean;
  headerClassName?: string;
  contentClassName?: string;
  onOpen?: (id: string, state: boolean) => void;
  children: React.ReactNode;
  headerIcon?: React.ReactNode;
  itemScope?: boolean;
  itemType?: string;
  itemProp?: string;
}

const Collapsible: React.FC<Props> = ({
  title,
  className,
  headerClassName,
  contentClassName,
  children,
  startOpen,
  open,
  skipTitleTranslation,
  id,
  onOpen,
  headerIcon,
  ...rest
}) => {
  const content = React.useRef<HTMLDivElement>(null);
  const [openState, setOpen] = React.useState(startOpen || open);
  const [isInit, setIsInit] = React.useState(false);
  const [animating, setAnimating] = React.useState(true);

  const contentStyle = useSpring({
    from: {
      maxHeight: openState ? 0 : content.current?.scrollHeight,
    },
    to: {
      maxHeight: openState ? content.current?.scrollHeight : 0,
    },
    onRest: () => setAnimating(false),
  });

  function handleToggleOpen() {
    if (onOpen) {
      onOpen(id || '', !openState);
    }
    if (open === undefined) {
      setOpen(!openState);
    }
  }

  React.useEffect(() => {
    if (isInit) {
      setAnimating(true);
    }
  }, [isInit]);

  React.useEffect(() => {
    if (!openState && !isInit) {
      setAnimating(false);
    } else {
      setAnimating(true);
      setIsInit(true);
    }
    if (open !== undefined && openState !== open) {
      setOpen(open);
    }
  }, [open, openState, isInit]);
  return (
    <div id={id} className={clsx(classes.root, className)} {...rest}>
      {Boolean(title) && (
        <button className={clsx(headerClassName)} type="button" onClick={handleToggleOpen}>
          <Txt skipTranslation={skipTitleTranslation}>{title}</Txt>
          <ChevronIcon
            className={clsx(classes.icon, openState ? classes.iconOpen : classes.iconClosed)}
          />
          {headerIcon}
        </button>
      )}
      {animating ? (
        <animated.div
          style={contentStyle}
          className={clsx(classes.content, contentClassName)}
          ref={content}
        >
          {children}
        </animated.div>
      ) : (
        <div
          style={{ maxHeight: openState ? 'auto' : 0 }}
          className={clsx(classes.content, openState && classes.openContent, contentClassName)}
          ref={content}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Collapsible;
