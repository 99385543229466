import Cookies from 'js-cookie';

function getAllowSocialMedia() {
  const rawCookieData = Cookies.get('cookie-consent');

  if (rawCookieData) {
    const cookies = JSON.parse(rawCookieData);

    if (cookies && cookies.settings.includes('mar')) {
      return true;
    }
  }
  return false;
}

export const allowSocialMedia = getAllowSocialMedia();

/* eslint-disable */
export function initTwitter() {
  const scriptNode = document.getElementById('twitter-wjs');
  if (scriptNode) {
    scriptNode.parentNode.removeChild(scriptNode);
  }

  !(function (d, s, id) {
    var js;
    var fjs = d.getElementsByTagName(s)[0];
    var p = /^http:/.test(d.location) ? 'http' : 'https';
    if (!d.getElementById(id)) {
      js = d.createElement(s);
      js.id = id;
      js.src = p + '://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);
    }
  })(document, 'script', 'twitter-wjs');
}

export function initFacebook() {
  const x = '123775991104422'; // Our App ID

  window.fbAsyncInit = function () {
    // SDK loaded, initialize it
    FB.init({
      appId: x,
      xfbml: true,
      version: 'v2.7',
      channelUrl: '//www.qmee.com/channel.html',
      cookie: true,
    });
    // JS SDK initialized, now you can use it
    FB.XFBML.parse();
  };

  // load the JavaScript SDK
  (function (d, s, id) {
    var js;
    var fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

export function shareTwitter({ url, text }) {
  window.open(
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText,
    )}&url=${encodeURIComponent('https://www.qmee.com/')}`,
    '_blank',
    'location=yes,height=480,width=520,scrollbars=yes,status=yes',
  );
}

export function shareFacebook({ url, title, description, ref, imageUrl }) {
  const obj = {
    method: 'share_open_graph',
    action_type: 'og.shares',
    display: 'popup',
    action_properties: JSON.stringify({
      object: {
        'og:url': url,
        'og:image': imageUrl,
        'og:title': title,
        'og:description': description,
        'og:ref': ref,
      },
    }),
  };
  FB.ui(obj, () => {});
}
