/**
 * @generated SignedSource<<1ef6a549cc6a1188f6cc099c3366d14d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type SurveyStatus = "AVAILABLE" | "FAIL" | "PENDING" | "SUCCESS";
import { FragmentRefs } from "relay-runtime";
export type SurveyList_surveys$data = {
  readonly surveys: {
    readonly id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly status: SurveyStatus;
        readonly url: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"SurveyItem_survey">;
      };
    }>;
  } | null;
  readonly responseQuality: {
    readonly score: number;
  } | null;
  readonly " $fragmentType": "SurveyList_surveys";
};
export type SurveyList_surveys$key = {
  readonly " $data"?: SurveyList_surveys$data;
  readonly " $fragmentSpreads": FragmentRefs<"SurveyList_surveys">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./SurveyListRefetchQuery.graphql')
    }
  },
  "name": "SurveyList_surveys",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "concreteType": "SurveyConnection",
      "kind": "LinkedField",
      "name": "surveys",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Survey",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SurveyItem_survey"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResponseQuality",
      "kind": "LinkedField",
      "name": "responseQuality",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e1b5e3824b04c975793785df9cac456c";

export default node;
