import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Streak_streak$data } from './__generated__/Streak_streak.graphql';

interface StreakTier {
  precursorMessage: string;
  tiers: { minStreakLength: number; messages: string[] }[];
}
const EXPIRING_MESSAGES: StreakTier = {
  precursorMessage: 'Oinqs Expire In',
  tiers: [
    {
      minStreakLength: 1,
      messages: ['First Oinq Unlocked!'],
    },
    {
      minStreakLength: 2,
      messages: ['Times A Ticking!', 'Get Your Streak On'],
    },
    {
      minStreakLength: 5,
      messages: ["Don't stop yet!", "Don't stop now!"],
    },
    {
      minStreakLength: 10,
      messages: ['Times A Ticking!', "Don't Lose Out"],
    },
    {
      minStreakLength: 15,
      messages: ["Keep 'Em Going!", "Don't Lose Out"],
    },
  ],
};

const ENCOURAGING_MESSAGES: StreakTier = {
  precursorMessage: 'Next Available In',
  tiers: [
    {
      minStreakLength: 1,
      messages: ['First Oinq Unlocked!'],
    },
    {
      minStreakLength: 2,
      messages: ['Oinq Unlocked!'],
    },
    {
      minStreakLength: 5,
      messages: ['Congratulations!', 'Bonus Unlocked!', 'Unlock Next Oinq'],
    },
    {
      minStreakLength: 10,
      messages: ['Looking Good!', 'Ya Running Hot!'],
    },
    {
      minStreakLength: 15,
      messages: ['Super Streaker!', "You're Smoking"],
    },
  ],
};

const TIME_FORMAT = 'HH[h] mm[m]';

function generateMessage(streakLength: number, isExpiring: boolean) {
  dayjs.extend(duration);

  const messages = isExpiring ? EXPIRING_MESSAGES : ENCOURAGING_MESSAGES;
  let activeTierIndex =
    messages.tiers.findIndex((element) => element.minStreakLength > streakLength) - 1;
  if (activeTierIndex == -2) {
    activeTierIndex = messages.tiers.length - 1;
  }
  const randomisedMessage =
    messages.tiers[activeTierIndex].messages[
      streakLength % messages.tiers[activeTierIndex].messages.length
    ];

  return {
    precursorMessage: messages.precursorMessage,
    message: randomisedMessage,
  };
}

export default function generateDescriptionData(streak: Streak_streak$data) {
  const now = dayjs();
  if (streak.length == 0 || !streak.expiresAt) {
    return {
      todaysStatus: 'INCOMPLETE',
      description: { precursorMessage: 'Start Streak!', message: [] },
    };
  } else if (
    streak.expiresAt === streak.unlocksAt ||
    dayjs(streak.expiresAt).isBefore(streak.unlocksAt)
  ) {
    const timeLeft = dayjs.duration(dayjs(streak.expiresAt).diff(now)).format(TIME_FORMAT);
    return {
      todaysStatus: 'INCOMPLETE',
      description: generateMessage(streak.length, true),
      time: formatTime(timeLeft),
    };
  } else {
    const unlocksIn = dayjs.duration(dayjs(streak.unlocksAt).diff(now)).format(TIME_FORMAT);
    return {
      todaysStatus: 'COMPLETE',
      description: generateMessage(streak.length, false),
      time: formatTime(unlocksIn),
    };
  }
}

function formatTime(time: string) {
  if (time === '00h 00m') {
    return '0m';
  }
  return time.replace(/00h /g, '').replace(/^0+/, '');
}
