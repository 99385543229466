import React from 'react';
import useIsAdmin from './useIsAdmin';
import useLocalStorageState from './useLocalStorageState';

interface FilterInputs {
  webcamSurveys: boolean;
  PIISurveys: boolean;
}
interface FilterSetters {
  setWebcamSurveys: (value: boolean) => void;
  setPIISurveys: (value: boolean) => void;
}
export default function useSurveyFilterInputs(): [FilterInputs, FilterSetters] {
  const [webcamSurveys, setWebcamSurveys] = useLocalStorageState('webcamSurveys', true);
  const [PIISurveys, setPIISurveys] = useLocalStorageState('PIISurveys', true);
  const [adminSurveys, setAdminSurveys] = useLocalStorageState('adminSurveys', false);
  const isAdmin = useIsAdmin();
  const showAdminSurveyInfo = isAdmin && adminSurveys;
  const inputs = React.useMemo(
    () => ({
      webcamSurveys,
      PIISurveys,
      trackingParams: showAdminSurveyInfo
        ? [
            {
              key: 'am',
              value: 'true',
            },
          ]
        : [],
    }),
    [webcamSurveys, PIISurveys, showAdminSurveyInfo],
  );
  const setters = React.useMemo(
    () => ({
      setWebcamSurveys,
      setPIISurveys,
      setAdminSurveys,
    }),
    [setWebcamSurveys, setPIISurveys, setAdminSurveys],
  );
  return [inputs, setters];
}
