/**
 * @generated SignedSource<<c5101cb19f51ff4b640ed699e4b9efe6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Streak_streak$data = {
  readonly expiresAt: string | null;
  readonly length: number;
  readonly unlocksAt: string;
  readonly id: string;
  readonly " $fragmentType": "Streak_streak";
};
export type Streak_streak$key = {
  readonly " $data"?: Streak_streak$data;
  readonly " $fragmentSpreads": FragmentRefs<"Streak_streak">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./StreakGamificationStreakRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "Streak_streak",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "length",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlocksAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "GamificationStreak",
  "abstractKey": null
};

(node as any).hash = "a7033f74b213747c929ef6d533263162";

export default node;
