import quill from './icons/quill.svg';
import snail from './icons/snail.svg';
import attention from './icons/attention.svg';
import offense from './icons/offense.svg';

export type SurveyFraudType =
  | 'GENERAL'
  | 'OPEN_ENDED'
  | 'SPEEDING'
  | 'TRAP_QUESTION'
  | '%future added value';

interface FraudTypeDescription {
  title: string;
  description: string;
  icon: string;
}
export const FRAUD_TYPE_MAPPING: Record<SurveyFraudType, FraudTypeDescription | null> = {
  GENERAL: {
    title: 'Quality reasons',
    description:
      'Sometimes our providers are not very specific. Follow our tips to prevent these in the future.',
    icon: offense,
  },
  TRAP_QUESTION: {
    title: 'Failed attention check',
    description:
      'The Survey Provider thought your answers were either inconsistent or they may have placed a trap question in their survey.',
    icon: attention,
  },
  OPEN_ENDED: {
    title: 'Illogical answers',
    description: 'The Survey Provider thought you had written gibberish as an answer',
    icon: quill,
  },
  SPEEDING: {
    title: 'Speeding',
    description:
      'The Survey Provider thought you were speeding through the survey and not paying enough attention',
    icon: snail,
  },
  '%future added value': null,
};

export const RESPONSE_QUALITY_TEST_GROUP = 'survey_score';
