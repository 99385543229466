import React, { ReactNode, useCallback } from 'react';
import { graphql } from 'react-relay';
import dynamic from 'next/dynamic';
import classes from './SurveyList.module.scss';
import { SurveyList_surveys$key } from './__generated__/SurveyList_surveys.graphql';
import Survey from '../../molecules/SurveyItem/SurveyItem';
import FinishedItem from '../../molecules/SurveyItem/FinishedItem';
import { useRefetchableLiveFragment } from '../../../relay/useLiveFragment';
import useRefreshInFocus from '../../../hooks/useRefreshInFocus';
import ItemList from '../../molecules/ItemList/ItemList';

import Txt from '../../atoms/Txt/Txt';

const DynamicSurveysLoadingAnimation = dynamic(
  () => import('components/organisms/SurveyList/SurveysLoadingAnimation'),
);

const DynamicNoSurveys = dynamic(() => import('components/organisms/NoSurveys/NoSurveys'));

export interface Props {
  surveysRef: SurveyList_surveys$key;
  showFinished?: boolean;
  children?: ReactNode;
}

const fragmentSpec = graphql`
  fragment SurveyList_surveys on Query @refetchable(queryName: "SurveyListRefetchQuery") {
    surveys(input: $input, first: $first) {
      id
      edges {
        node {
          id
          status
          url
          ...SurveyItem_survey
        }
      }
    }
    responseQuality {
      score
    }
  }
`;
export const SurveyItemWrapper = React.forwardRef<HTMLLIElement, { children: ReactNode }>(
  function SurveyItemWrapper({ children }, ref) {
    return <li ref={ref}>{children}</li>;
  },
);

const RELOAD_RETRY_DELAYS = [1000, 2000, 4000];

const SurveyList: React.FC<Props> = ({ surveysRef, showFinished, children }) => {
  const [data, refetch] = useRefetchableLiveFragment(fragmentSpec, surveysRef);
  const [activeHistoryItem, setActiveHistoryItem] = React.useState('');
  const [isExpandedActiveItem, setIsExpandedActiveItem] = React.useState(false);
  const availableSurveys = React.useMemo(() => {
    const result =
      data.surveys?.edges.filter(
        (surveyEdge) =>
          surveyEdge.node.status === 'AVAILABLE' ||
          (surveyEdge.node.status === 'PENDING' && surveyEdge.node.url),
      ) || [];
    // Want the part completed surveys to appear first
    return result.reverse();
  }, [data.surveys]);

  const finishedSurveys = React.useMemo(
    () =>
      data.surveys?.edges.filter(
        (surveyEdge) => surveyEdge.node.status !== 'AVAILABLE' && !surveyEdge.node.url,
      ) || [],
    [data.surveys],
  );

  const handleRefetch = useCallback(() => {
    refetch({}, { fetchPolicy: 'store-and-network' });
  }, [refetch]);

  useRefreshInFocus(handleRefetch, 60 * 1000);

  const handleActiveHistoryItem = (id: string, state: boolean) => {
    setActiveHistoryItem(id);
    setIsExpandedActiveItem(state);
  };

  const [remainingReloadRetries, setRemainingReloadRetries] = React.useState(
    RELOAD_RETRY_DELAYS.length,
  );

  React.useEffect(() => {
    if (remainingReloadRetries <= 0) {
      return undefined;
    }
    if (availableSurveys.length > 0) {
      setRemainingReloadRetries(0);
      return undefined;
    }
    const timeOut = setTimeout(() => {
      setRemainingReloadRetries(remainingReloadRetries - 1);
      handleRefetch();
    }, RELOAD_RETRY_DELAYS[RELOAD_RETRY_DELAYS.length - remainingReloadRetries] || 4000);
    return () => clearTimeout(timeOut);
  }, [availableSurveys.length, remainingReloadRetries, handleRefetch]);

  return (
    <>
      {availableSurveys.length > 0 ? (
        <ItemList animate>
          {availableSurveys.map((surveyEdge) => (
            <SurveyItemWrapper key={surveyEdge.node.id}>
              <Survey className={classes.survey} surveyRef={surveyEdge.node} />
            </SurveyItemWrapper>
          ))}
          {children}
        </ItemList>
      ) : remainingReloadRetries > 0 ? (
        <DynamicSurveysLoadingAnimation />
      ) : (
        <DynamicNoSurveys />
      )}
      {showFinished && finishedSurveys.length > 0 ? (
        <div className={classes.finishItemContainer}>
          <Txt component="h3">Recently Finished</Txt>
          {finishedSurveys.map((surveyEdge, index) => (
            <FinishedItem
              isOpen={activeHistoryItem === surveyEdge.node.id && isExpandedActiveItem}
              onOpen={handleActiveHistoryItem}
              key={surveyEdge.node.id}
              className={index % 2 === 0 ? 'odd' : ''}
              surveyRef={surveyEdge.node}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default SurveyList;
