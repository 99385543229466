import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFragment } from 'react-relay/hooks';
import { MODAL_TYPES, useModalContext } from '../../templates/ModalProvider/ModalProvider';
import classes from './FinishedItem.module.scss';
import { SurveyType, surveyItemFragmentSpec } from './SurveyItem';
import { SurveyItem_survey$key } from './__generated__/SurveyItem_survey.graphql';

import Button from 'components/atoms/Button/Button';
import useInTestGroup from '../../../hooks/useInTestGroup';
import RejectedIcon from '../../assets/cross.svg';
import CompletedIcon from '../../assets/tick.svg';
import WarningIcon from '../../assets/warning.svg';
import A from '../../atoms/A/A';
import Collapsible from '../../atoms/Collapsible/Collapsible';
import LoadingIndicator from '../../atoms/LoadingIndicator/LoadingIndicator';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import Txt from '../../atoms/Txt/Txt';
import { useLastPending } from '../../organisms/SurveyFeedbackModal/SurveyFeedbackModal';
import {
  FRAUD_TYPE_MAPPING,
  RESPONSE_QUALITY_TEST_GROUP,
} from '../../pages/ResponseQuality/constants';
import { RESPONSEQUALITY } from '../../pages/pages';

export interface Props {
  surveyRef: SurveyItem_survey$key;
  className?: string;
  isOpen: boolean;
  onOpen?: (id: string, state: boolean) => void;
}

const STATUS_ICONS: { [key: string]: React.FunctionComponent } = {
  SUCCESS: CompletedIcon,
  FAIL: RejectedIcon,
  PENDING: LoadingIndicator,
};
function formatDuration(duration: number | null): string {
  if (!duration) {
    return '';
  }
  if (duration > 1) {
    return `${duration} mins`;
  }
  return `${duration} min`;
}

const usePopupSurvey = (survey: SurveyType) => {
  const { showModal } = useModalContext();
  const [isPendingOpen, setIsPendingOpen] = useState(false);
  const lastPending = useLastPending();

  useEffect(() => {
    if (
      !isPendingOpen &&
      survey.status === 'PENDING' &&
      lastPending !== survey.id &&
      !survey.tags.includes('restart_allowed')
    ) {
      setIsPendingOpen(true);
      return showModal(MODAL_TYPES.SURVEY_FEEDBACK_MODAL, survey);
    }
    return undefined;
  }, [survey, lastPending, isPendingOpen, showModal]);
};

export const FRAUD_TERMS_TEST_GROUP = 'fraud_terms';

const FinishedItem: React.FC<Props> = ({ surveyRef, className, isOpen, onOpen }) => {
  const survey = useFragment(surveyItemFragmentSpec, surveyRef);
  const inSurveyScoreTestGroup = useInTestGroup(RESPONSE_QUALITY_TEST_GROUP);
  const inFraudTermsTestGroup = useInTestGroup(FRAUD_TERMS_TEST_GROUP);

  usePopupSurvey(survey);
  const StatusIcon = STATUS_ICONS[survey.status] || null;

  const { showModal } = useModalContext();
  const handleReportSurvey = (reportDetails: any) => {
    showModal(MODAL_TYPES.SURVEY_FEEDBACK_MODAL, reportDetails);
  };

  const fraudType = survey.fraudType ? FRAUD_TYPE_MAPPING[survey.fraudType] : null;

  const showFraudTerms = fraudType && (inFraudTermsTestGroup || inSurveyScoreTestGroup);

  return (
    <Collapsible
      className={clsx(classes.collapsible, className)}
      key={survey.id}
      open={isOpen}
      onOpen={onOpen}
      id={survey.id}
      title={
        <div className={classes.titleContainer}>
          <p>
            {survey.title}
            {showFraudTerms && <WarningIcon className={classes.warning} />}
          </p>
          <div className={classes.titleContent}>
            <p>{survey.reward?.formatted || ''}</p>
            <p>{formatDuration(survey.duration)}</p>
          </div>
        </div>
      }
      headerClassName={clsx(classes.historyHeader, className)}
    >
      <div className={clsx(classes.collapsibleContent, className)}>
        {survey.startedAt && (
          <>
            <div className={classes.finishedItemRow}>
              <p>#{survey.supportId}</p>
              <p>{dayjs(survey.startedAt).format('L - LT')}</p>
            </div>
            <div className={classes.finishedItemRow}>
              <p className={clsx(classes.status, survey.status.toLowerCase())}>
                {StatusIcon && <StatusIcon />}
                {survey.statusDescription}&nbsp;
                {showFraudTerms && (
                  <Tooltip
                    tip={
                      <div className={classes.fraudTip}>
                        <p>{fraudType.description}</p>
                        {inSurveyScoreTestGroup && (
                          <Txt component={A} to={RESPONSEQUALITY.path}>
                            More info
                          </Txt>
                        )}
                      </div>
                    }
                  >
                    {inSurveyScoreTestGroup ? (
                      <A
                        className={classes.term}
                        to={inSurveyScoreTestGroup ? RESPONSEQUALITY.path : '#'}
                      >
                        <WarningIcon className={classes.warning} />
                        {fraudType.title}
                      </A>
                    ) : (
                      <div className={classes.term}>
                        <WarningIcon className={classes.warning} />
                        {fraudType.title}
                      </div>
                    )}
                  </Tooltip>
                )}
              </p>
              {survey.status !== 'PENDING' && (
                <Button
                  buttonType="tertiary"
                  color="accent"
                  onClick={() => handleReportSurvey(survey)}
                >
                  <Txt component="">Report survey</Txt>
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Collapsible>
  );
};

export default FinishedItem;
