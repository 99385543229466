import { useCallback, useEffect, useState } from 'react';

export default function useRefreshInFocus(callback: () => void, intervalTime: number) {
  const [inFocus, setInFocus] = useState(true);
  const onFocus = useCallback(() => {
    callback();
    setInFocus(true);
  }, [callback]);

  const onBlur = () => {
    setInFocus(false);
  };

  useEffect(() => {
    if (inFocus) {
      const interval = setInterval(() => {
        callback();
      }, intervalTime);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [inFocus, callback, intervalTime]);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [onFocus]);
}
